$fontcolor:  rgb(10%, 10%, 10%);
$backgroundcolor: rgb(98%, 98%, 98%);
$fontfamily: "Montserrat", sans-serif;

.container {
  font-family: "Montserrat", sans-serif;
  width: 100vw;
}

.infographicContainer{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;

  img {
    max-height: 80vh;
    max-width: 100vw;
  }
}

.icon{
  width: 40%;
  padding-top: 100px;
  padding-bottom: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.title {
  font-family: "Montserrat", sans-serif;
  color: #FB582F;
  font-weight: bold;
  font-size: 36pt;
  text-align: center;
}

.navBar{
  background-color: white;
  color: #ff5722;
  height: 15vh;
  padding-top: 25px;
  padding-bottom: 15px;
}

.titleBox{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 87, 34);
  height:85vh;

}
.mainPage{
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 40px;
}

.imagine{
  font-family: "Pacifico", sans-serif;
  font-size: 28px;
  color:rgb(255, 87, 34);
  margin-top: 10vh;
  margin-bottom: 20vh;
  font-weight: bold;
  font-style: italic;
  align-items: center;
  justify-content: center;
  right: 50%;
  text-align: center;
}

.imagineReasons {
  margin-bottom: 20vh;
}

.appDesc {
  width: 100vw;
  height: auto;
  min-height: 50vh;
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: rgb(255, 87, 34);
}

@media only screen and (min-width: 768px) {
  .appDesc {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100vw;
    height: auto;
    min-height: 50vh;
    margin-top: 100px;
    margin-bottom: 100px;
    background-color: rgb(255, 87, 34);
  }
}

.topBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;

  .reasonBox {
    width: 66%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) {
  .topBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;

    .reasonBox {
      width: 33%;
      color: orange;
      flex-direction: column;
      justify-content: space-between;

      &:hover {
        color: blue;
      }

      &:active {
        color: green;
      }
    }
  }
}


.basicTextBold{
  color:rgb(255, 87, 34);
  display: flex;
  justify-content: 'center';
  align-items: center;
  font-weight: bold;
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  margin: 100px;
}
.basicTextBoldWhite{
  color:white;
  display: flex;
  justify-content: 'center';
  align-items: center;
  font-weight: bolder;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin: 20px;
}
.basicText{
  color:rgb(255, 87, 34);
  font-weight: lighter;
  display: flex;
  justify-content: 'center';
  align-items: center;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
}

.basicTextWhite{
  color:white;
  display: flex;
  justify-content: 'center';
  align-items: center;
  font-family: "Montserrat", sans-serif;
  margin: 20px;
}

.contentBottom{
  border-top: 5pt solid #ffffff;
  padding: 15pt;
  border-collapse: separate;
  text-align: left;
}

#search-bar {
  margin-bottom: 20px;

  input {
    font-size: 1.5em;
    border-radius: .25em;
  }
}

#video-detail {
  width: 100%;
}

#video-section {
  display: flex;

  li {
    list-style: none;
    cursor: pointer;
    display: flex;
    width: 25vw;
    padding: 20px;
  }
  li:hover {
    background: darken( $backgroundcolor, 10% );
  }

}

.active {
  color: #ff5722;
}

.item {
  order: 1;
}

// Nav CSS
.navBar {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-evenly;
  flex-flow: row;
  list-style-type: none;

  li {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
}

.navItem {
  text-decoration: none;
  border-radius: 4px;

  &:hover {
  color: orange;
  fontSize: 20px;
  text-decoration: none;
  }

  &:focus {
    color: #f4511e;
  }
}

.navItemDownload {
  text-decoration: none;
  border-radius: 4px;
  color: #007bff;

  &:hover {
    color: orange;
    fontSize: 20px;
    text-decoration: none;
  }

  &:focus {
  }
}

.donateItem {
  display: inline-block;
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  padding: 10px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #FFFFFF;
  }
}

.donateItem span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.donateItem span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.donateItem:hover span {
  padding-right: 25px;
  text-decoration: none;
}

.donateItem:hover span:after {
  opacity: 1;
  right: 0;
  text-decoration: none;
}

// Footer CSS
.footerContainer {
  padding-right: 40px;
  padding-left: 40px;
}

.footerBar {
  border-top: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-around;
  list-style-type: none;
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 8px;
  padding-left: 0px;

  li {
    flex-basis: 33.333333%;
    flex-direction: row;
    display: flex;
    justify-content: center;
  }
}

.footerItem {
  text-decoration: none;
  color: lightgray;

  &:hover {
    fontSize: 20px;
    text-decoration: none;
    color: #f4511e;
  }
}

.footerLogo {
  max-width: 10%;
  max-height: 10%;
}


//Landing CSS

.styleObj {
    font-size: 25;
    font-weight: bold;
    color: #B5D8CF;
    padding-top: 100px;
    background-color: black;
}

.boldText {
    font-family: Montserrat, sans-serif;
    font-size: 20;
    font-weight: bold;
    color: black;
    justify-content: center;
}

.downloadContainer {
  height: 105vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FB582F;
}

.appStoreLogo {
  max-height: 70px;
}

.googlePlayLogo {
  max-height: 100px;
}

.appFeatures {
  margin-bottom: 20vh;
}

.styledTitle {
  font-family: Pacifico;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40;
  margin-bottom: 25;
  font-size: 80;
}

.submitButton {
  display: inline-block;
  border-radius: 4px;
  background-color: white;
  border: none;
  color: #f4511e;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: darkorange;
  }
}

.emailForm input {
  outline: none;

  &:focus {
    outline: none;
  }
}

.Toastify__toast--default {
  color: #f4511e;
}

.Toastify__progress-bar--default {
  color: #f4511e;
}

//About CSS
.teamRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

@media only screen and (min-width: 768px) {
  .teamRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }
}

.teamMember {
  display: flex;
  width: 40%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 5px;
  text-align: center;


  img {
    max-width: 140px;
    margin: 5px;
  }

  .memberName {
    font-weight: bold;
    font-size: 22px;
    margin-top: 5px;
  }

  .memberPosition {
    font-weight: bolder;
    font-size: 16px;
    margin-bottom: 8px;
  }

  .memberDetails {
    font-weight: lighter;
    font-size: 12px;
  }
}

.blueHeaderStyle{
    font-family: Montserrat, sans-serif;
    font-size: 25;
    font-weight: bold;
    color: #005975;
    padding-top: 100px;
}

// Create styling

.createEventFormContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.createEventForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  margin: 30px;
  width: 40vw;

  label {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
}

.createContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.createTitleContainer {
  background-color: #FB582F;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin: 20px;
  width: 100vw;
  height: 10vh;
}

.submitButton {
  background-color: #FB582F;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin: 20px;
  width: 200px;
  height: 10vh;
  border-radius: 4px;
}

#eventDescription {
  height:100px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #FB582F;
}

input:focus + .slider {
  box-shadow: 0 0 1px #FB582F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.blueBodyStyle{
    font-family: 'Montserrat, sans-serif';
    font-size: 12;
    font-weight: 'extra-light';
    color: '#005975';
}

// Map CSS
.explorePageContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  overflow: hidden;
  align-items: center;
  overflow: auto;
}

.eventListContainer {
  flex-basis: 20%;
  background-color: #ff5722;
  height: 80vh;
  display: flex;
  overflow: scroll;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
  border-radius: 4px;
}

.eventListItem {
  flex-basis: 10%;
  height: 100px;
  width: 90%;
  background-color: #fff;
  opacity: 90%;
  font-size: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 2px;
  margin: 5px;
  padding: 2px;
  transition: 0.3s;
  cursor: pointer;
}

.eventListItem:hover {
  transform: scale(1.05);
}

.eventListTitle {
  font-weight: bold;
  font-size: 12px;
}

.findOutMoreButton {
  background-color: #FB582F;
  color:white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  margin: 20px;
  width: 200px;
  height: 10vh;
  border-radius: 4px;
  transition: 0.3s;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: #dd411a;
  }
}

.mapContainer {
  flex-basis: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exploreMap {
  height: 80vh;
  width: 70vw;
}

.mapPinPopup {
  height: 100px;
  width: 100px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-size: 10px;
  text-align: center;
  border-radius: 7px;
}

.mapPinTitle {
  font-weight: bold;
  font-size: 12px;
}
